import MonitoringAlert from "components/monitoring/monitoring-alert.component";
import { MonitoringType } from "components/monitoring/monitoring-type";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";

interface IProps {
  systemName?: string;
  isActiveInCluster?: boolean;
}

function Offline(): ReactElement {
  const { t } = useTranslation("monitoring");

  return (
    <MonitoringAlert
      text={t("messages.externalSystemOffline")}
      type={MonitoringType.ExternalSystemOffline}
      color={Constants.Colors.error}
    />
  );
}

function PassiveExternalSystemOffline(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("monitoring");

  return (
    <MonitoringAlert
      text={t("messages.passiveExternalSystemOfflineWithName", {
        activeOrPassive: props.isActiveInCluster
          ? t("messages.active")
          : t("messages.passive"),
        externalSystemName: props.systemName ? props.systemName : "",
      })}
      type={MonitoringType.PassiveExternalSystemOffline}
      color={Constants.Colors.brightOrange}
    />
  );
}

function ExternalSystemServiceOffline(): ReactElement {
  const { t } = useTranslation("monitoring");
  return (
    <MonitoringAlert
      text={t("messages.externalSystemServiceOffline")}
      type={MonitoringType.ExternalSystemServiceOffline}
      color={Constants.Colors.brightOrange}
    />
  );
}

const ExternalSystemMonitoringAlert = {
  MonitoringAlert: MonitoringAlert,
  Offline: Offline,
  PassiveExternalSystemOffline: PassiveExternalSystemOffline,
  ExternalSystemServiceOffline: ExternalSystemServiceOffline,
};

export default ExternalSystemMonitoringAlert;
